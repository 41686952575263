import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { isPresent } from "../../helpers/common";
import { Loader } from "../../common/Loader";
import { loadLearnTemplateData } from "../../store/learn/actions";
import { Wrapper } from "../details";
import PageContent from "../details/PageContent";
import { checkLoadingEffect, isStoreLoading } from "../../helpers/callbacks_helpers";
import { useParams } from "react-router-dom";

export const LearnTemplateDetails = ({ learn, loadLearnTemplateData }) => {
  const params = useParams()
  if (isPresent(params.id)) {
    checkLoadingEffect(learn, () => loadLearnTemplateData(params.id))
  }
  useEffect(() => {
    if (learn.loaded && learn.template_id !== params.id) loadLearnTemplateData(params.id)
  }, [params.id, learn.loaded]);

  if (isStoreLoading(learn)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper>
      <PageContent isIndividualDecision={true} />
    </Wrapper>;
  }
}
const mapStateToProps = ({ learn }) => ({ learn })
export default connect(mapStateToProps, { loadLearnTemplateData })(LearnTemplateDetails);
