import React, { useEffect, useState } from 'react';
import { isBlank, isPositive, isPresent, pluralize, toSentence } from '../../helpers/common';
import { connect } from "react-redux";
import {
  needToLoadHomepageSection,
  searchDashboardDecisions
} from '../../store/homepage/actions';
import Button from "react-bootstrap/Button";
import { Pagination } from "../../common/Pagination";
import { Loader } from "../../common/Loader";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { loadAdmissions } from "../../store/decision_admissions/actions";
import SearchDecisionFilters, {
  DEFAULT_FILTER_TOGGLE_VALUE,
  DEFAULT_SEARCH_FILTER_TOGGLE_VALUE
} from "./filters/SearchDecisionFilters";
import {
  allContacts,
  CATEGORY_TO_STATUS,
  DEFAULT_STATE, isDefaultCategoriesFilters,
  isDefaultFilters,
  OWNERS_FILTER_SEPARATOR,
  selectedCategoryOptionsByCategory,
  STATE_FILTER_SEPARATOR
} from '../../helpers/filter_helpers';
import { homeSearchState } from "./SearchSection";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import {useParams} from "react-router-dom";
import DSightOnlyPill from "./helpers/DSightOnlyPill";
import SearchRow, { resetSearchAction } from "./helpers/SearchRow";
import { loadSearchFilters, updateSearchFilters } from "../../utils/Api";
import { loadContacts } from "../../store/contacts/actions";
import {isSearchDecisions} from "../../helpers/home_helpers";
import SortByActivity from "./search/SortByActivity";
import SearchResultsList from "./search/SearchResultsList";

const prepareItems = ({ search_results }) => {
  const result = [];

  if (isPositive(search_results.total_sets)) result.push(pluralize(search_results.total_sets, 'flow'))
  if (isPositive(search_results.total_decisions)) result.push(pluralize(search_results.total_decisions, 'decision'))
  if (isBlank(result)) result.push(pluralize(search_results.total_count, 'decision'))

  return result;
}

const DecisionsCount = ({ home }) =>
  <div className="decisions-count text-nowrap py-3 me-3">
    { prepareItems(home).filter(isPresent).join(', ') }
  </div>


const CategoryRow = ({
                       home, category,
                       resetCategoryOptionsByCategory
                     }) => {
  const selectedCategoryOptions = selectedCategoryOptionsByCategory(category, home);

  return <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
                 onClick={() => resetCategoryOptionsByCategory(category)}
                 hidden={isBlank(selectedCategoryOptions)}>
    <span className="pe-1">{category.name}:</span>
    <span className="fw-normal">{toSentence(selectedCategoryOptions)}</span>
    <i className="fas fa-times ms-1" />
  </Button>
}

const SelectedFiltersPills = ({
                                home, contactsData, user, org_categories,
                                resetOwnersFilter, resetSharedWithFilter, resetStateFilter, resetCategoryOptionsByCategory, resetDSightOnly
                              }) =>
  <div className="col filter-pills">
    <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
            onClick={resetOwnersFilter}
            hidden={isBlank(home.owners)}>
      <span className="pe-1">Owners:</span>
      <span className="fw-normal">
        {
          toSentence(home.owners.split(OWNERS_FILTER_SEPARATOR)
            .map((owner_email) => allContacts(contactsData, user)
              .find((contact) => owner_email === contact.email)?.full_name))}
      </span>
      <i className="fas fa-times ms-1" />
    </Button>
    <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
            onClick={resetSharedWithFilter}
            hidden={isBlank(home.shared_with_admissions)}>
      <span className="pe-1">Shared with:</span>
      <span className="fw-normal">
        {toSentence(home?.shared_with_admissions?.map((admission) => JSON.parse(admission).name))}
      </span>
      <i className="fas fa-times ms-1" />
    </Button>
    <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1" onClick={resetStateFilter} hidden={home.state === DEFAULT_STATE}>
      <span className="pe-1">Current status:</span>
      <span className="fw-normal">
        {toSentence(home.state.split(STATE_FILTER_SEPARATOR).map(state => CATEGORY_TO_STATUS[state]))}
      </span>
      <i className="fas fa-times ms-1" />
    </Button>
    {
      org_categories.available_categories.map((category, _i) =>
        <CategoryRow {...{ home, category, resetCategoryOptionsByCategory }} key={`selected-category-${category.slug}`} />
      )
    }
    <DSightOnlyPill {...{ home, resetDSightOnly }}/>
  </div>

const SearchDecisionTab = ({
                             home, org_categories, decision_admissions, contactsData, user, decision_set,
                             loadOrgCategories, loadAdmissions, loadContacts, searchDashboardDecisions
                           }) => {
  const [view, setView] = useState(DEFAULT_FILTER_TOGGLE_VALUE);
  const [searchView, setSearchView] = useState(DEFAULT_SEARCH_FILTER_TOGGLE_VALUE);

  async function fetchSearchFilters(){
    setLoading(true);
    const searchFilters = await loadSearchFilters();
    const { data: { search_filters }} = searchFilters
    setTimeout(() => {
      if (Object.keys(search_filters || {}).includes('search')) setSearchQuery(search_filters?.search || '');
      if(search_filters?.view) setView(search_filters?.view);
      const filters = {
        ...search_filters,
        ...(decisionSetPresent && { decision_set_slug: id }),
        ...(search_filters?.view && { view: search_filters.view }),
        search_view: searchView
      }
      searchDashboardDecisions(filters, () => { setLoading(false) });
    }, 50)
  }

  const {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  } = homeSearchState(home)
  const filtersUsed = isPresent(searchQuery) || isPresent(home.owners) || isPresent(home.shared_with_admissions) || home.state !== DEFAULT_STATE || isPresent(home.category_options) || home.d_sight_only;
  const decisions = home.search_results.data;
  const showNullResults = filtersUsed && isBlank(decisions);

  checkLoadingEffect(org_categories, loadOrgCategories)
  checkLoadingEffect(contactsData, loadContacts);
  checkLoadingEffect(decision_admissions, loadAdmissions);
  const { id } = useParams();
  const decisionSetPresent = !!id;

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'search_results')) {
      if(decisionSetPresent) {
        searchDashboardDecisions({
          decision_set_slug: id,
          search_view: searchView
        })
      } else {
        fetchSearchFilters();
      }
    }
  }, [home.search_results.loaded, home.search_results.loading, decisionSetPresent]);

  useEffect(() => {
    if(!loading) {
      const timeoutId = setTimeout(() => {
        searchDashboardDecisions(
          { search: searchQuery,
            search_view: searchView,
            sort_order: home.sort_order,
            current_page: 1,
            ...(decisionSetPresent && { decision_set_slug: id })
          })
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      }
    }
    setLoading(false);
  }, [searchQuery]);

  const onChangeSortingCriteria = (eventKey) => searchDashboardDecisions(
    { search: searchQuery,
      search_view: searchView,
      sort_order: eventKey,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const resetAllFilters = () => {
    setLoading(true);
    setSearchQuery('');
    searchDashboardDecisions(
      {
        owners: "",
        shared_with_admissions: [],
        state: DEFAULT_STATE,
        category_options: [],
        search_view: searchView,
        search: '',
        current_page: 1,
        d_sight_only: false,
        ...(decisionSetPresent && { decision_set_slug: id })
      })
  };

  const resetDSightOnly = () => searchDashboardDecisions(
    {
      d_sight_only: false,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const resetOwnersFilter = () => searchDashboardDecisions(
    {
      owners: "",
      current_page: 1,
      search_view: searchView,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const resetSharedWithFilter = () => searchDashboardDecisions(
    {
      shared_with_admissions: [],
      current_page: 1,
      search_view: searchView,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const resetStateFilter = () => searchDashboardDecisions(
    {
      state: DEFAULT_STATE,
      current_page: 1,
      search_view: searchView,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const resetCategoryOptionsByCategory = (category) => {
    const allCategoryOptionsIdsByCategory = category.options.map((option) => option.id)
    const filteredSelectedCategories = home.category_options.filter((category_option_id) => !allCategoryOptionsIdsByCategory.includes(category_option_id));
    searchDashboardDecisions(
      {
        category_options: filteredSelectedCategories,
        current_page: 1,
        search_view: searchView,
        ...(decisionSetPresent && { decision_set_slug: id })
      })
  };

  const handlePageClick = (new_page) => searchDashboardDecisions(
    {
      current_page: new_page,
      search_view: searchView,
      ...(decisionSetPresent && { decision_set_slug: id })
    })

  const handleViewChange = async (view) => {
    setView(view);
    await updateSearchFilters({ view });
  }
  const handleSearchViewChange = async (search_view) => {
    if(searchView === search_view) return;

    setSearchView(search_view);
    searchDashboardDecisions({ search_view });
  }

  return <>
    <div className="row" hidden={isPresent(decision_set)}>
      <div className="col">
        <h1>Search decision bank</h1>
      </div>
    </div>
    <SearchRow {...{ searchQuery, setSearchQuery }}
               rowStyles={'search-tab-content'} isSearchTab={isSearchDecisions(home)}
               resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)}>
      <SearchDecisionFilters {...{
        view, searchView,
        decisionSetPresent,
        setView: handleViewChange,
        setSearchView: handleSearchViewChange,
        isSearchTab: isSearchDecisions(home)
      }} />
    </SearchRow>
    <div className="row pt-2" hidden={isDefaultFilters(home) && isDefaultCategoriesFilters(home)}>
      <SelectedFiltersPills {...{
          home, user, org_categories, contactsData,
          resetOwnersFilter, resetSharedWithFilter, resetStateFilter,
          resetCategoryOptionsByCategory, resetDSightOnly
        }}
      />
    </div>
    <div className="row">
      <div className="col d-flex">
        <DecisionsCount home={home}/>
        <SortByActivity store={home} onChangeSortingCriteria={onChangeSortingCriteria}/>
      </div>
    </div>
    <div className="row loading mb-3" hidden={!home.search_results.loading}>
      <div className="col">
        <Loader/>
      </div>
    </div>
    <div className="row" hidden={home.search_results.loading}>
      <SearchResultsList
        {...{
          showNullResults, decisions, resetAllFilters, decision_set,
          decision_set_slug: id, isListLine: view !== DEFAULT_FILTER_TOGGLE_VALUE,
          searchView
        }}
      />
    </div>
    <div className="row pb-4" hidden={home.search_results.loading || home.search_results.total_pages === 1}>
      <Pagination page={home.search_results.current_page} totalPages={home.search_results.total_pages}
                  setPage={handlePageClick} totalCount={home.search_results.total_count} />
    </div>
  </>;
}
const mapStateToProps = ({ home, org_categories, contacts, current_user, decision_admissions }) => ({
  home, org_categories, contactsData: contacts, user: current_user, decision_admissions
});
const mapDispatchToProps = (dispatch) => ({
  searchDashboardDecisions: (data, callback = null) => {
    dispatch(searchDashboardDecisions(data, callback))
  },
  loadOrgCategories: () => {
    dispatch(loadOrgCategories())
  },
  loadAdmissions: () => {
    dispatch(loadAdmissions())
  },
  loadContacts: () => {
    dispatch(loadContacts())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchDecisionTab);
