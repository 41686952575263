import React, { useState, useEffect, useMemo } from "react";
import { connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import { isBlank } from "../../helpers/common";
import DecisionAssignToBlock from "../../tree_view/modals/helpers/DecisionAssignToBlock";
import {
  assignDraftDecision,
  updateDecision,
  updateRecommendation,
  updateTemplate
} from "../../store/modals/actions";
import { createRecommendation } from "../../store/sidebar/actions";
import Decision from "../../models/decision";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import { fetchAssignedToCollaborator } from "../../helpers/decision_user_helpers";
import { isDraftDecision } from "../../models/decision_state";
import {resetTemplateSetData} from "../../store/template_set/common_actions";
import EntryPoint from "../../EntryPoint";

export const showAssignDecisionModal = (modal) => modal.type === "AssignDecisionModal" && modal.shown

const onSubmit = (assignedToUser, setAssignedToUser, decisionObject, modal, decision, recommendation, setSubmit,
                  updateDecision, updateRecommendation, assignDraftDecision, onClose, resetDecisionSetData, createRecommendation,
                  isTemplateSet = false, updateTemplate, resetTemplateSetData) => () => {
  const reset = () => {
    setAssignedToUser('');
    setSubmit(false);
    isTemplateSet ? resetTemplateSetData() : null;
    onClose();
  }
  const callback = (status) => status ? reset() : setSubmit(false);
  const updateRecommendationCallback = (recommendationSlug) => updateRecommendation({
    decision_recommendation: { assigned_collaborator: assignedToUser },
    recommendation_slug: recommendationSlug,
    assign_from_decision_set: true
  }, callback, modal.slug)

  setSubmit(true);
  if(isDraftDecision(decision)) {
    assignDraftDecision(modal.slug, { assigned_collaborator: assignedToUser }, callback)
  } else if(decisionObject.isRecommendationFlow) {
    if(isBlank(recommendation)) {
      createRecommendation({
        choice_entry_widget_type: 'open_ended',
      }, (success, recommendationSlug = '') => {
        if(success) updateRecommendationCallback(recommendationSlug)
      }, modal.slug)
    } else {
      updateRecommendationCallback(recommendation.slug)
    }
  } else if (isTemplateSet) {
    EntryPoint.instance.objectSlug = modal.slug
    updateTemplate({ template: {assigned_collaborator: assignedToUser} }, callback)
  } else {
    updateDecision({ template: { assigned_collaborator: assignedToUser }, assign_from_decision_set: true }, callback, modal.slug)
  }
}

const AssignDecisionModal = ({
                               modal, key, show, allowPending = true,
                               onClose, updateDecision, updateRecommendation,
                               assignDraftDecision, resetDecisionSetData, createRecommendation,
                               isTemplateSet = false, updateTemplate, resetTemplateSetData,
                               allowGuest = true
                             }) => {
  if(isBlank(modal.decision)) return null;

  const decisionObject = useMemo(() => new Decision(modal.decision), [modal.decision, show]);
  const [assignedToUser, setAssignedToUser] = useState(fetchAssignedToCollaborator(modal.decision, decisionObject));
  const [submit, setSubmit] = useState(false);
  const submissionCallback = onSubmit(assignedToUser, setAssignedToUser, decisionObject, modal, modal.decision,
    modal.decision.recommendation, setSubmit, updateDecision, updateRecommendation, assignDraftDecision, onClose,
    resetDecisionSetData, createRecommendation, isTemplateSet, updateTemplate, resetTemplateSetData
  )

  useEffect(() => {
    setAssignedToUser(fetchAssignedToCollaborator(modal.decision, decisionObject))
  }, [show, decisionObject, modal.decision])

  return <Modal key={key} size="md" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Assign decision</h2>
      <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, allowPending, className: 'mt-3', allowNew: false, allowGuest }} />
    </Modal.Body>
    <ModalDoneFooterP0 onClose={submissionCallback} disabled={submit} />
  </Modal>
}
const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps, {
  updateDecision, updateRecommendation, assignDraftDecision, resetDecisionSetData, createRecommendation,
  updateTemplate, resetTemplateSetData
})(AssignDecisionModal);
