import {
  loadSetDecisionUsers,
  loadSetDecisionDrivers,
  loadSetDecisionInvites,
  loadSetDecisionFollowups,
  loadSetDecisionChoices
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { updateDecisionSetData, updateSetsFailure } from "./common_actions";

const BATCH_SIZE = 50;
const LOAD_DECISIONS_DATA_KEYS = [
  'user', 'users', 'deciding_user', 'user_supports', 'user_approvals', 'drivers', 'invites', 'followups', 'choices'
];

export const getLoadedDecisionData = (decisionInState) => {
  return LOAD_DECISIONS_DATA_KEYS.reduce((acc, key) => {
    if (key in decisionInState) {
      acc[key] = decisionInState[key];
    }
    return acc;
  }, {});
};

export const mergedDecisionDataWithLoadedDecisionData = (decision_set, stateSetDecisions) => {
  return decision_set.decisions.map(d => {
    const loadedDecisionInState = stateSetDecisions.find(state_decision => state_decision.slug === d.slug && state_decision?.loaded);
    if(loadedDecisionInState) {
      return { ...d, ...getLoadedDecisionData(loadedDecisionInState), loaded: true, loading: false };
    } else {
      return { ...d, loaded: false, loading: true };
    }
  });
}

const fetchedData = (dispatch, setSlug, decisionSlug, flowEnum) => {
  const fetchUsers = loadSetDecisionUsers({ setSlug, slug: decisionSlug });
  const fetchInvites = loadSetDecisionInvites({ setSlug, slug: decisionSlug });

  let fetchDrivers = Promise.resolve({ data: { drivers: [] } });
  let fetchFollowups = Promise.resolve({ data: { followups: [] } });
  let fetchChoices = Promise.resolve({ data: { choices: [] } });

  if (flowEnum !== 'unstarted_tree') {
    fetchDrivers = loadSetDecisionDrivers({ setSlug, slug: decisionSlug });
    fetchFollowups = loadSetDecisionFollowups({ setSlug, slug: decisionSlug });
    fetchChoices = loadSetDecisionChoices({ setSlug, slug: decisionSlug });
  }

  return { fetchUsers, fetchDrivers, fetchInvites, fetchFollowups, fetchChoices };
}

export const loadAllDecisionData = (setSlug, decisionSlug, flowEnum) => (dispatch, getState) => {
  const {fetchUsers, fetchDrivers, fetchInvites, fetchFollowups, fetchChoices} = fetchedData(dispatch, setSlug, decisionSlug, flowEnum);
  return Promise.all([fetchUsers, fetchDrivers, fetchInvites, fetchFollowups, fetchChoices])
    .then(responses => {
      const [usersResponse, driversResponse, invitesResponse, followupsResponse, choicesResponse] = responses;
      const {
        user = {},
        users = [],
        deciding_user = {},
        user_supports = [],
        user_approvals = []
      } = usersResponse.data || {};
      const { drivers = [] } = driversResponse.data || {};
      const { invites = [] } = invitesResponse.data || {};
      const { followups = [] } = followupsResponse.data || {};
      const { choices = [] } = choicesResponse.data || {};

      return {
        decisionSlug,
        data: {
          user,
          users,
          deciding_user,
          user_supports,
          user_approvals,
          drivers,
          invites,
          followups,
          choices,
          loading: false,
          loaded: true
        }
      };
    })
    .catch(error => {
      console.error("Failed to load decision data:", error);
      dispatch(updateSetsFailure());
      return null;
    });
}

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const checkVisibleElements = (dispatch, lookupSlug = null, loadAction = () => {}) => {
  let attempts = 0;
  const maxAttempts = 10;
  const delay = 500;

  const checkVisibility = () => {
    const element = lookupSlug ? document.querySelector(`[class*="load-decision-slug-${lookupSlug}"]`) : document.querySelector('[class*="load-decision-slug-"]');
    if (element) {
      const observer = new IntersectionObserver((entries, observer) => {
        if (entries.some(entry => entry.isIntersecting)) {
          loadAction();
          observer.disconnect();
        }
      }, { threshold: 0.1 });

      observer.observe(element);
    } else if (attempts < maxAttempts) {
      attempts++;
      setTimeout(checkVisibility, delay);
    }
  };

  checkVisibility();
};

export const completeLoadingDecisionData = (decision_set, decisions, callback = () => {}, batchSize = BATCH_SIZE) => (dispatch, getState) => {
  const decisionChunks = chunkArray(decisions, batchSize);

  const processChunk = async (chunk, index) => {
    const loadAllDecisionsPromises = chunk.map(decision => dispatch(loadAllDecisionData(decision_set.slug, decision.slug, decision.flow_enum)));
    const results = await Promise.all(loadAllDecisionsPromises);

    const state = getState();
    const currentDecisions = state.decision_set.decisions;

    const updatedDecisions = currentDecisions.map(decision => {
      const result = results.find(res => res && res.decisionSlug === decision.slug);
      return result ? { ...decision, ...result.data } : decision;
    });

    dispatch(updateDecisionSetData({
      ...state.decision_set,
      decisions: updatedDecisions
    }));
  };

  const processAllChunks = async () => {
    for (let i = 0; i < decisionChunks.length; i++) {
      await processChunk(decisionChunks[i], i);
    }
    dispatch(updateDecisionSetData({ loaded: true }));
    callback();
  };

  processAllChunks().then();
};