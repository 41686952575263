import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import { JoditTextEditor } from '../../../common/JoditTextEditor';
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedResultsInput = ({ setUpdatingData, decision, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const [expectedResults, setExpectedResults] = useState(recommendation.expected_results || '');

  const onChangeExpectedResults = (_e) => {
    setUpdatingData(true);
    updateRecommendation({
      decision_recommendation: { expected_results: expectedResults, slug: recommendation.slug }
    }, () => setUpdatingData(false));
  };

  const accordionObject = {
    header: <h3 className="mb-0">Expected results</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={expectedResults}
                           placeholder="Describe what is expected to happen"
                           setValue={setExpectedResults}
                           hasOnChangeAction={true}
                           autofocus={false} />,
    defaultExpand: isPresent(recommendation.expected_results)
  };

  return <div onBlur={onChangeExpectedResults}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecommendation })(ExpectedResultsInput);
