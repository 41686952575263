import {
  HOMEPAGE_LOAD_DATA,
  HOMEPAGE_LOAD_DATA_FAILED,
  HOMEPAGE_LOAD_DATA_STARTED,
  HOMEPAGE_RESET_DSIGHTS_DATA, HOMEPAGE_RESET_FORECAST_DATA, HOMEPAGE_RESET_REPORTS_DATA,
  HOMEPAGE_RESET_SEARCH,
  HOMEPAGE_UPDATE_DATA
} from "./types";
import {
  createNewGroupData,
  deleteCurrentUser,
  deleteGroupData,
  getTemplateDashboardDataRequest,
  inviteOrgUsersData,
  inviteUsersToGroupData,
  loadAdminTemplatesData,
  loadAdminTemplateSetsData,
  loadAssignedObjects,
  loadDecisionsData,
  loadDecisionSetsData,
  loadDsightDataRequest,
  loadGroupsData,
  loadHomePageData,
  loadLearnDataRequest,
  loadReportInsightsDataRequest,
  loadTemplatesData,
  loadTemplateSetsData,
  loadUsersData,
  removeUserFromOrg,
  resendOrgUserInvite,
  revokeOrgUserInvite,
  searchDecisions,
  signOutRequest,
  startDecisionRequest,
  switchUserSelectedOrg,
  updateCurrentUserData,
  updateGroupData,
  updateHomepageLastTab,
  updateOrgUserData,
  updatePendingUserData,
  updateUserGroupsData,
  startReportRequest,
  loadReportsDataRequest, loadForecastSimulatorScenarios, createForecastSimulatorScenario, loadSetData
} from "../../utils/Api";
import { updateUserData } from "../current_user/actions";
import {
  failedResponseHandlerWithDispatchCallback,
  isResponseFailed,
  isResponseRedirected
} from "../../helpers/store_helpers";
import { TABS_URLS } from "../../helpers/home_helpers";
import { updateOrgData } from "../current_org/actions";
import { isPresent, isBlank, uniqueByCallback } from "../../helpers/common";
import { BASE_FORECAST_SIMULATOR, BASE_SECTION, BASE_SECTION_WITH_PAGINATION, DEFAULT_SORT_ORDER } from "./reducers";
import {
  DEFAULT_STATE,
  isDefaultFilters,
  isDefaultReportFilters,
  isDefaultTemplateFilters,
  isDefaultSortOrder
} from "../../helpers/filter_helpers";
import { resetDSightGoal } from "../d_sight_goal/actions";
import { resetMetrics } from "../metrics/actions";
import {resetOrgReportPropertiesState} from "../org_report_properties/actions";
import { updateShareData } from "../share/actions";
import { updateDecisionSetData } from "../decision_set/common_actions";
import EntryPoint from "../../EntryPoint";
import {mergedDecisionDataWithLoadedDecisionData} from "../decision_set/load_decision_data_actions";

// Homepage

export function loadHomepageData() {
  return (dispatch) => {
    dispatch(loadHomepageStarted({}));
    loadHomePageData().then((response) => {
      if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure });
      const { data } = response;
      if (isResponseRedirected(response)) return document.location.href = data.redirect_path;

      const { homepage, no_results_image_url, request_access_image_url, request_access_sent_image_url } = data;
      dispatch(updateShareData({ ...homepage.share_data }));
      dispatch(loadHomepageSuccess({
        ...homepage,
        no_results_image_url,
        request_access_image_url,
        request_access_sent_image_url,
        loaded: true
      }));
    })
  }
}

export const updateHomepageCurrentTab = (tab, callback = () => {})  => (_dispatch, getState) => {
  updateHomepageLastTab(tab, { org_slug: getState().current_org?.slug }).then(response => {
    callback(!isResponseFailed(response))
  })
}

export const switchOrg = (slug, callback = () => {}) => (dispatch) => {
  switchUserSelectedOrg(slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback);

    const { data } = response;
    const { current_user, current_org, share_data } = data;
    dispatch(updateUserData({ ...current_user }));
    dispatch(updateOrgData({ ...current_org, forecast_simulator_scenarios: [] }));
    dispatch(updateShareData({ ...share_data }));
    dispatch(resetDSightGoal())
    dispatch(resetMetrics())
    dispatch(resetSearch());
    dispatch(resetOrgReportPropertiesState())
    setTimeout(() => dispatch(switchOrgData()), 100);
    callback(true)
  })
}

export const signOut = (callback = () => {}) => () => {
  signOutRequest().then(response => {
    callback(response?.ok)
  })
}

// Decisions Center
export const loadDecisionsSection = (section) => (dispatch) => {
  const updateData = {}
  updateData[section] = { ...BASE_SECTION, loading: true }
  dispatch(updateHomepageData(updateData))

  const data = { dashboard: true, dashboard_params: {} }
  data.dashboard_params[section] = true

  loadDecisionsData(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { decisions } = data

    const updateData = {}
    updateData[section] = { loaded: true, loading: false, data: decisions }

    dispatch(updateHomepageData(updateData))
  })
}

export const loadAssignedObjectsSection = () => (dispatch) => {
  dispatch(updateHomepageData({ assigned_objects: { ...BASE_SECTION, loading: true } }))

  loadAssignedObjects().then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })
    const { data } = response;
    const { assigned_drivers, assigned_decisions, assigned_polls } = data

    dispatch(updateHomepageData({ assigned_objects: { loading: false, loaded: true, assigned_drivers, assigned_decisions, assigned_polls } }))
  })
}

// Dashboard Templates
export const loadDashboardTemplates = (data = {}, tab = TABS_URLS.create_decision) => (dispatch, getState) => {
  dispatch(updateHomepageData({ create_decision: { ...BASE_SECTION, loading: true }, ...data }))

  loadTemplatesData({
    ...searchTemplateArgs(getState), tab
  }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { templates } = data
    dispatch(updateHomepageData({ create_decision: { data: templates, loaded: true, loading: false } }))
  })
}
export const loadDashboardTemplateAdditionalData = (slug) => (dispatch, getState) => {
  const newData = {}
  newData[slug] = { ...BASE_SECTION, loading: true }
  dispatch(updateHomepageData({
    create_decision_additional_data: { ...getState().home.create_decision_additional_data, ...newData }
  }))

  getTemplateDashboardDataRequest(slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { template } = data
    const newData = {}
    newData[slug] = { loaded: true, loading: false, data: template }
    dispatch(updateHomepageData({
      create_decision_additional_data: { ...getState().home.create_decision_additional_data, ...newData }
    }))
  })
}
export const loadDashboardAdminTemplates = (data = {}) => (dispatch, getState) => {
  dispatch(updateHomepageData({ admin_templates: { ...BASE_SECTION, loading: true }, ...data }))
  loadAdminTemplatesData(searchTemplateArgs(getState)).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { templates, share_data } = data
    dispatch(updateHomepageData({ admin_templates: { data: templates, loaded: true, loading: false } }))
    dispatch(updateShareData({ ...share_data }))
  })
}

export const loadAdminTemplateSets = (data = {}) => (dispatch, getState) => {
  dispatch(updateHomepageData({ admin_template_sets: { ...BASE_SECTION, loading: true }, ...data }))
  loadAdminTemplateSetsData(searchTemplateArgs(getState)).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { template_sets } = data
    dispatch(updateHomepageData({ admin_template_sets: { data: template_sets, loaded: true, loading: false } }))
  })
}

export const loadTemplateSets = (data = {}) => (dispatch, getState) => {
  dispatch(updateHomepageData({ template_sets: { ...BASE_SECTION, loading: true }, ...data }))

  loadTemplateSetsData(searchTemplateArgs(getState)).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { template_sets } = data
    dispatch(updateHomepageData({ template_sets: { data: template_sets, loaded: true, loading: false } }))
  })
}

export const loadDecisionSets = (requestData = {}, data = {}) => (dispatch) => {
  dispatch(updateHomepageData({ decision_sets: { ...BASE_SECTION, loading: true }, ...data }))

  loadDecisionSetsData(requestData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { decision_sets } = data
    dispatch(updateHomepageData({ decision_sets: { data: decision_sets, loaded: true, loading: false } }))
  })
}

// Dashboard Users
export const loadDashboardUsers = (requestData = {}) => (dispatch) => {
  dispatch(updateHomepageData({ users: { ...BASE_SECTION, loading: true } }))

  loadUsersData(requestData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    if (isPresent(requestData.groups_data)) {
      const { users, org_sso, groups, invite_org_users_default_notification_text, default_user_avatar_url } = data
      dispatch(updateHomepageData({
        users: { data: users, loading: false, loaded: true },
        groups: { data: groups, loading: false, loaded: true },
        org_sso, default_user_avatar_url, invite_org_users_default_notification_text
      }))
    } else {
      const { users, org_sso, default_user_avatar_url } = data
      dispatch(updateHomepageData({
        users: { data: users, loading: false, loaded: true },
        org_sso, default_user_avatar_url
      }))
    }
  })
}

// DSight data
export const loadDSightData = (requestData = {}) => (dispatch) => {
  dispatch(updateHomepageData({ dsight: { ...BASE_SECTION, loading: true } }))

  loadDsightDataRequest(requestData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { dsight_data } = data
    dispatch(updateHomepageData({
      dsight: { ...requestData, loaded: true, loading: false, data: dsight_data }
    }))
  })
}

// Forecast Simulator Data
export const loadForecastSimulatorData = (requestData = {}) => (dispatch) => {
  dispatch(updateHomepageData({ forecast_simulator: { ...BASE_FORECAST_SIMULATOR, loading: true } }))

  loadForecastSimulatorScenarios(requestData).then(response => {
    if (isResponseFailed(response)) {
      return failedResponseHandlerWithDispatchCallback(dispatch, {
        ...response,
        callback: (error) => {
          console.log(`loadForecastSimulatorData - error = ${error}`)
          return updateHomepageData({ forecast_simulator: { ...BASE_FORECAST_SIMULATOR, loaded: true, loading: false, error } })
        }
      })
    }
    const { data } = response;
    const { scenarios, configs } = data
    dispatch(updateHomepageData({
      forecast_simulator: { loaded: true, loading: false, data: { scenarios, configs }, error: '' }
    }))
  })
}

export const createForecastSimulatorRecord = (config_id, requestData = {}, callback) => (dispatch, getState) => {
  dispatch(updateHomepageData({ forecast_simulator: { ...getState().home.forecast_simulator, loading: true } }))

  createForecastSimulatorScenario(config_id, requestData).then(response => {
    if (isResponseFailed(response)) {
      if (response.data['status'] === 'error' && response.data['code'] === 'invalid_config_id') {
        document.location.reload()
        return;
      }
      dispatch(updateHomepageData({ forecast_simulator: { ...getState().home.forecast_simulator, loading: false } }))
      return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: callback })
    }
    const { data } = response;
    const { scenario } = data
    const newForecastSimulatorData = getState().home.forecast_simulator.data;
    newForecastSimulatorData.scenarios = [
      ...newForecastSimulatorData.scenarios,
      { ...scenario, user_email: scenario.data.attributes.user_email }
    ]
    dispatch(updateHomepageData({
      forecast_simulator: { loaded: true, loading: false, data: newForecastSimulatorData }
    }))
    dispatch(updateOrgData({ forecast_simulator_scenarios: newForecastSimulatorData.scenarios }))
    callback(scenario)
  })
}

export const loadLearnData = (requestData = {}) => (dispatch) => {
  dispatch(updateHomepageData({ learning: { ...BASE_SECTION, loading: true }, create_decision: { ...BASE_SECTION, loading: true } }))

  loadLearnDataRequest(requestData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { learning_data, templates } = data
    dispatch(updateHomepageData({
      learning: { loaded: true, loading: false, data: learning_data  },
      create_decision: { loading: false, loaded: true, data: templates }
    }))
  })
}

export const loadReportInsightsData = (data = {}) => (dispatch) => {
  dispatch(updateHomepageData({ report_insights: { ...BASE_SECTION, loading: true, ...data }, ...data }))

  loadReportInsightsDataRequest({}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { report_insights_data } = data
    dispatch(updateHomepageData({ report_insights: { loaded: true, loading: false, data: report_insights_data }}))
  })
}

export const loadReportsData = (data = {}) => (dispatch, getState) => {
  dispatch(updateHomepageData({ reports: { ...BASE_SECTION_WITH_PAGINATION, loading: true, ...data }, ...data }))
  const { search, sort_order, property_options, report_property_options } = getState().home;

  loadReportsDataRequest({ search, sort_order, property_options, report_property_options, page: getState().home.reports.current_page }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { reports_data, total_count, total_pages, current_page } = data
    dispatch(updateHomepageData({
      reports: { loaded: true, loading: false, data: reports_data, total_count, total_pages, current_page },
    }))
  })
}

export const updateOrgUser = (slug, data = {}, callback = ()=>{}) => (dispatch, getState) => {
  updateOrgUserData(slug, data).then(response => {
    updateUserDataInArray(dispatch, getState, 'org_user', slug, response, callback)
  })
}

export const removeOrgUser = (slug, callback = ()=>{}) => (dispatch, getState) => {
  removeUserFromOrg(slug).then(response => {
    removeUserDataInArray(dispatch, getState, 'org_user', slug, response, callback)
  })
}

export const inviteOrgUsers = (data = {}, callback = () => {}) => (dispatch, getState) => {
  inviteOrgUsersData(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

    const { data } = response;
    const { invites, org_users } = data;
    const users = uniqueByCallback([
      ...getState().home.users.data,
      ...org_users,
      ...invites
    ], (a) => `${a.type}-${a.slug}`)
    dispatch(updateHomepageData({ users: { ...getState().home.users, data: users } }))
    callback(true)
  })
}

export const updatePendingUser = (slug, data = {}, callback = () => {}) => (dispatch, getState)=> {
  updatePendingUserData(slug, data).then(response => {
    updateUserDataInArray(dispatch, getState, 'pending_invite', slug, response, callback)
  })
}

export const resendOrgInvite = (slug, callback = () => {}) => (dispatch)=> {
  resendOrgUserInvite(slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

    callback(true)
  })
}
export const revokeOrgInvite = (slug, callback = ()=>{}) => (dispatch, getState) => {
  revokeOrgUserInvite(slug).then(response => {
    removeUserDataInArray(dispatch, getState, 'pending_invite', slug, response, callback)
  })
}

// Dashboard Groups

export const loadDashboardGroups = (data = {}) => (dispatch) => {
  dispatch(updateHomepageData({ groups: { ...BASE_SECTION, loading: true } }))

  loadGroupsData(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { groups } = data
    dispatch(updateHomepageData({ groups: { loaded: true, loading: false, data: groups } }))
  })
}
export const inviteUsersToGroup = (slug, contacts, callback = () => {}) => (dispatch, getState) => {
  inviteUsersToGroupData(slug, { contacts }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback);

    const { data } = response;
    const { users } = data

    dispatch(updateHomepageData({ users: { ...getState().home.users, data: users } }))
    callback(true)
  })
}
export const removeExistedUserFromGroup = (user, group, callback = () => {}) => (dispatch, getState) => {
  const data = {
    group_slugs: user.group_slugs.filter(slug => slug !== group.slug),
    old_group_slugs: user.group_slugs,
    org_user_slug: user.slug
  }
  updateUserGroupsData(group.slug, data).then(response => {
    updateUserDataInArray(dispatch, getState, 'org_user', user.slug, response, callback)
  })
}
export const removePendingUserFromGroup = (invite, group, callback = () => {}) => (dispatch, getState) => {
  const data = { group_slugs: invite.group_slugs.filter(slug => slug !== group.slug) }
  updatePendingUserData(invite.slug, data).then(response => {
    updateUserDataInArray(dispatch, getState, 'pending_invite', invite.slug, response, callback)
  })
}
export const createGroup = (name, contacts, callback = () => {}) => (dispatch, getState) => {
  createNewGroupData({ name, contacts }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

    const { data } = response;
    const { group, users } = data
    const groups = [
      ...getState().home.groups.data, group,
    ]
    const available_org_shared_items = [
      ...getState().share_data.available_org_shared_items, buildGroupAdmission(group)
    ]
    dispatch(updateHomepageData({
      groups: { ...getState().home.groups, data: groups },
      users: { ...getState().home.users, data: users }
    }))
    dispatch(updateShareData({ ...getState().share_data, available_org_shared_items }));
    callback(true)
  })
}

const buildGroupAdmission = (group) => {
  return { admittable_id: group.slug, admittable_type: 'Group', label: group.name }
}

export const updateGroup = (slug, data, callback = () => {}) => (dispatch, getState) => {
  updateGroupData(slug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

    const { data } = response;
    const { group_data } = data
    const groups = [
      ...getState().home.groups.data.map(g => g.slug === slug ? { ...g, ...group_data } : g)
    ]
    const available_org_shared_items = [
      ...getState().share_data.available_org_shared_items.map(g => g.admittable_id === group_data.slug ? buildGroupAdmission(group_data) : g)
    ]

    dispatch(updateHomepageData({ groups: { ...getState().home.groups, data: groups  } },))
    dispatch(updateShareData({ ...getState().share_data, available_org_shared_items }));
    callback(true)
  })
}
export const deleteGroup = (slug, callback = () => {}) => (dispatch, getState) => {
  deleteGroupData(slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

    const groups = [...getState().home.groups.data.filter(g => g.slug !== slug)]
    const available_org_shared_items = [...getState().share_data.available_org_shared_items.filter(g => g.admittable_id !== slug)]
    dispatch(updateHomepageData({ groups: { ...getState().home.groups, data: groups } }))
    dispatch(updateShareData({ ...getState().share_data, available_org_shared_items }));
    callback(true)
  })
}

// Homepage Search

export const searchDashboardDecisions = (data, callback = null) => (dispatch, getState) => {
  const { decision_set_slug, ...searchData } = data;
  dispatch(updateHomepageData({ search_results: { ...BASE_SECTION_WITH_PAGINATION, loading: true, ...searchData }, ...searchData }))
  const { search, sort_order, state, owners, shared_with_admissions, category_options, d_sight_only, search_view } = getState().home;
  const attrs = { search, sort_order, state, owners, shared_with_admissions, category_options, d_sight_only, page: getState().home.search_results.current_page, search_view };
  if (!!decision_set_slug) {
    attrs.decision_set_slug = decision_set_slug
  }
  searchDecisions(attrs).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure });

    const { data } = response;
    const { search_results, total_count, total_pages, current_page, total_sets, total_decisions } = data;
    dispatch(updateHomepageData({
      search_results: {
        data: search_results, loaded: true, loading: false,
        total_count, total_pages, current_page,
        total_sets, total_decisions
      }
    }))
    if (callback) callback(true)
  })
}

// Homepage Profile

export const updateProfile = (data, callback = () => {}) => (dispatch) => {
  updateCurrentUserData(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback);

    const { data } = response;
    const { user } = data;
    dispatch(updateUserData({ ...user }));
    callback(true)
  })
}

export const deleteAccount = (callback) => (dispatch) => {
  deleteCurrentUser().then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback);

    document.location.href = '/';
    callback(true)
  })
}

// Homepage Common actions

export const forceReloadHomepageDecisions = () => (dispatch) => {
  dispatch(updateHomepageData({
    draft: BASE_SECTION,
    approval_needed: BASE_SECTION,
    assigned_objects: BASE_SECTION,
    in_flight: BASE_SECTION,
    due_followup: BASE_SECTION,
    search_results: BASE_SECTION_WITH_PAGINATION,
    learning: BASE_SECTION,
    decision_sets: BASE_SECTION,
    dsight: BASE_SECTION,
    report_insights: BASE_SECTION,
    reports: BASE_SECTION_WITH_PAGINATION
  }))
}

export const forceReloadHomepageTemplates = () => (dispatch) => {
  dispatch(updateHomepageData({
    create_decision: BASE_SECTION,
    create_decision_additional_data: {},
    admin_templates: BASE_SECTION,
    dsight: BASE_SECTION,
    template_sets: BASE_SECTION,
    admin_template_sets: BASE_SECTION
  }))
}

export const forceReloadHomepageReportInsights = () => (dispatch) => {
  dispatch(updateHomepageData({
    report_insights: BASE_SECTION,
    reports: BASE_SECTION_WITH_PAGINATION
  }))
}

export const startNewDecision = (data = {}, callback = () => {}) => (dispatch) => {
  const isAddedToSet = isPresent(data.decision_set_slug)
  startDecisionRequest(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { slug } = data
    if (isAddedToSet)
      dispatch(updateDecisionSetData({ added_decision_slug: slug }));
    callback(slug)
  })
}

export const startNewReport = (data = {}, callback = () => {}) => (dispatch) => {
  startReportRequest(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure })

    const { data } = response;
    const { slug } = data
    callback(slug)
  })
}

const updateUserDataInArray = (dispatch, getState, type, slug, response, callback) => {
  if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

  const { data } = response;
  const { row } = data
  const users = [
    ...getState().home.users.data.map(u => u.type === type && u.slug === slug ? { ...u, ...row } : u)
  ]

  dispatch(updateHomepageData({ users: { ...getState().home.users, data: users } }))
  if (type === 'org_user' && getState().current_user.slug === slug) {
    dispatch(updateUserData({ ...row }))
  }
  callback(true)
}

const removeUserDataInArray = (dispatch, getState, type, slug, response, callback) => {
  if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadHomepageFailure }, callback)

  const users = [
    ...getState().home.users.data.filter(u => u.type !== type || u.slug !== slug)
  ]
  dispatch(updateHomepageData({ users: { ...getState().home.users, data: users } }))
  callback(true)
}

export const loadHomepageSuccess = (data) => ({
  type: HOMEPAGE_LOAD_DATA,
  payload: {
    ...data
  }
});

export const resetDSightsData = () => ({
  type: HOMEPAGE_RESET_DSIGHTS_DATA
});
export const resetReportsData = () => ({
  type: HOMEPAGE_RESET_REPORTS_DATA
});

export const resetForecastData = () => ({
  type: HOMEPAGE_RESET_FORECAST_DATA
});

export const resetSearch = () => ({
  type: HOMEPAGE_RESET_SEARCH
});

export const loadHomepageStarted = (query = {}) => ({
  type: HOMEPAGE_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});

export const updateHomepageData = (data) => ({
  type: HOMEPAGE_UPDATE_DATA,
  payload: {
    ...data
  }
});

export const switchOrgData = () => ({
  type: HOMEPAGE_UPDATE_DATA,
  payload: {
    draft: BASE_SECTION,
    approval_needed: BASE_SECTION,
    assigned_objects: BASE_SECTION,
    in_flight: BASE_SECTION,
    due_followup: BASE_SECTION,
    search_results: BASE_SECTION_WITH_PAGINATION,
    create_decision: BASE_SECTION,
    create_decision_additional_data: {},
    admin_template_sets: BASE_SECTION,
    template_sets: BASE_SECTION,
    decision_sets: BASE_SECTION,
    admin_templates: BASE_SECTION,
    users: BASE_SECTION,
    groups: BASE_SECTION,
    learning: BASE_SECTION,
    dsight: BASE_SECTION,
    report_insights: BASE_SECTION,
    reports: BASE_SECTION_WITH_PAGINATION,
    forecast_simulator: BASE_FORECAST_SIMULATOR,
    invite_org_users_default_notification_text: '',
    org_sso: false,
    state: 'all',
    owners: '',
    shared_with_admissions: [],
    category_options: [],
    template_users: [],
    d_sight_only: false
  }
});

export const reloadTemplatesData = () => ({
  type: HOMEPAGE_UPDATE_DATA,
  payload: {
    create_decision: BASE_SECTION,
    create_decision_additional_data: {},
    admin_templates: BASE_SECTION,
    template_sets: BASE_SECTION,
    admin_template_sets: BASE_SECTION,
    dsight: BASE_SECTION
  }
})

export const resetSearchAndFilters = () => (dispatch, getState) => {
  const home = getState().home;
  const defaultFilters = () => isBlank(home.search) && isDefaultFilters(home) && isDefaultTemplateFilters(home) &&
    isDefaultReportFilters(home) && isDefaultSortOrder(home)
  if(defaultFilters()) return;

  let updateOptions = {
    category_options: [],
    template_users: [],
    search: '',
    d_sight_only: false
  }
  const resetAdminTemplates = () => {
    updateOptions.admin_templates = BASE_SECTION
  }
  const resetTemplates = () => {
    updateOptions.create_decision = BASE_SECTION
  }
  const resetSearchTabResults = () => {
    updateOptions.search_results = BASE_SECTION_WITH_PAGINATION
    updateOptions.owners = ''
    updateOptions.state = DEFAULT_STATE
    updateOptions.shared_with_admissions = [],
    updateOptions.d_sight_only = false,
    updateOptions.sort_order = DEFAULT_SORT_ORDER
  }
  const resetReportInsights = () => {
    updateOptions.report_insights = BASE_SECTION
    updateOptions.property_options = []
    updateOptions.report_property_options = []
    updateOptions.sort_order = DEFAULT_SORT_ORDER
  }
  const resetReports = () => {
    updateOptions.reports = BASE_SECTION_WITH_PAGINATION
  }

  if(home.admin_templates.loaded) resetAdminTemplates()
  if(home.create_decision.loaded) resetTemplates()
  if(home.search_results.loaded) resetSearchTabResults();
  if(home.report_insights.loaded) resetReportInsights();
  if(home.reports.loaded) resetReports();
  dispatch(updateHomepageData(updateOptions))
}

export const loadHomepageFailure = error => ({
  type: HOMEPAGE_LOAD_DATA_FAILED,
  payload: {
    error
  }
});

export const needToLoadHomepageSection = (home, section) => !home[section].loaded && !home[section].loading
export const isHomepageSectionLoading = (home, section) => home[section].loading || !home[section].loaded
export const isHomepageSectionLoaded = (home, section) => !home[section].loading && home[section].loaded

const formatTemplateUsersFilterData = (template_users) => template_users.map(({ type, slug }) => ({ type, slug }))

const searchTemplateArgs = (getState) => {
  const { search, template_users, category_options, d_sight_only } = getState().home;
  return {
    search, category_options, d_sight_only, template_users: formatTemplateUsersFilterData(template_users)
  };
}
