import React, { useRef } from 'react'
import { connect } from "react-redux";
import { DoneBtnW100 } from "../../common/DoneButton";
import { isMobile } from "react-device-detect";
import ZTree from "./ZTree";
import { Loader } from "../../common/Loader";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {createDecisionSetSection, reorderDecisions, updateDecisionSetSection} from "../../store/decision_set/actions";
import {createTemplateSetSection, reorderTemplates, updateTemplateSetSection} from "../../store/template_set/actions";
import AssignDecisionModal, {showAssignDecisionModal} from "../modals/AssignDecisionModal";
import { openModal, closeModal, transferDataAndCloseModal } from "../../store/modals/actions";
import AddSectionModal, {showAddSectionModal} from "../modals/AddSectionModal";
import EditSectionModal, {showEditSectionModal} from "../modals/EditSectionModal";
import {checkVisibleDecisionsEffect} from "../../helpers/callbacks_helpers";
import { loadVisibleDecisions } from "../../store/decision_set/actions";

const Footer = ({ onClose }) =>
  <div className="row">
    <div className="col-12">
      <DoneBtnW100 onClose={onClose} />
    </div>
  </div>

const dragDecisionAction = (isTemplateSet, reorderDecisions, reorderTemplates) =>
  isTemplateSet ? reorderTemplates : reorderDecisions

const createSectionAction = (isTemplateSet, createDecisionSetSection, createTemplateSetSection) =>
  isTemplateSet ? createTemplateSetSection : createDecisionSetSection

const updateSectionAction = (isTemplateSet, updateDecisionSetSection, updateTemplateSetSection) =>
  isTemplateSet ? updateTemplateSetSection : updateDecisionSetSection


const PanelTreeEditor = ({
                           set,
                           modal,
                           preview = false,
                           isTemplateSet = false,
                           isDSight= false,
                           allowGuest = true,
                           onClose,
                           reorderDecisions,
                           reorderTemplates,
                           openModal,
                           transferDataAndCloseModal,
                           updateDecisionSetSection,
                           updateTemplateSetSection, createDecisionSetSection, createTemplateSetSection,
                           closeModal, loadVisibleDecisions,
                           ...opts
                        }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  checkVisibleDecisionsEffect(containerRef.current, loadVisibleDecisions);

  const onAddDecision = () => {
    onClose();
    if(isTemplateSet) {
      navigate(`/template_sets/${set.slug}/add_template`)
    } else {
      navigate(`/decision_sets/${set.slug}/add_decision`)
    }
  }

  const onAddSection = (e) => {
    e.preventDefault();
    openModal({ type: 'SetSectionsPanelAddSectionModal', set_section: { name: '' }})
  }

  return (
    <div className="pt-0 p-3">
      <div className="tree-container mb-2">
        <div className={`tree-body edit-mode`} ref={containerRef}>
          <ZTree {...{set, isTemplateSet, preview, isDSight}}
                 zTreeId={'decisionTree'}
                 dragDecisionAction={dragDecisionAction(isTemplateSet, reorderDecisions, reorderTemplates)} {...opts} />
        </div>
      </div>
      <div className={`${(isMobile ? "footer-done-space" : "")}`}>
        <div className="row mb-2">
          <div className="col-12">
            <Button onClick={onAddDecision} className="btn-secondary w-100">Add a decision</Button>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <Button onClick={onAddSection} className="btn-secondary w-100">Add a section</Button>
          </div>
        </div>
        <Footer onClose={onClose}/>
      </div>
      <div className="modals">
        <AssignDecisionModal key={'set-sections-add-decision'}
                             isTemplateSet={isTemplateSet}
                             allowGuest={allowGuest}
                             show={showAssignDecisionModal(modal)}
                             allowPending={true}
                             onClose={transferDataAndCloseModal}/>
        <AddSectionModal key={'set-sections-add-section'}
                         show={showAddSectionModal(modal)}
                         title={'Add a section'}
                         onClose={transferDataAndCloseModal}
                         createSectionAction={createSectionAction(isTemplateSet, createDecisionSetSection, createTemplateSetSection)}/>
        <EditSectionModal key={`set-sections-add-section-${modal.slug}`}
                          show={showEditSectionModal(modal)}
                          title={'Edit section name'}
                          updateSectionAction={updateSectionAction(isTemplateSet, updateDecisionSetSection, updateTemplateSetSection)}
                          onClose={transferDataAndCloseModal}/>
      </div>
    </div>
  )
}
const mapStateToProps = ({modal, decision_set, template_set}, {isTemplateSet}) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
export default connect(mapStateToProps, {
  reorderDecisions, reorderTemplates, openModal, transferDataAndCloseModal, updateDecisionSetSection, updateTemplateSetSection,
  createDecisionSetSection, createTemplateSetSection, closeModal, loadVisibleDecisions
})(PanelTreeEditor);
