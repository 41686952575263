import React from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent, successActions } from "../../../helpers/common";
import { redirectToHomeCallback } from "../../../helpers/decision_helpers";
import { Link, useNavigate } from "react-router-dom";
import { resetLearnData } from "../../../store/learn/actions";
import Button from 'react-bootstrap/Button';
import { setLearnFiltersSidebarOpen } from "../../../store/sidebar/actions";
import HamburgerDropdown from "./HamburgerDropdown";
import OrgLogo from "../../../tree_view/header/OrgLogo";

const LearnHeader = ({
                       learn, current_org, sidebar,
                       resetLearnData, setLearnFiltersSidebarOpen
                     }) => {
  const navigate = useNavigate();
  if (!learn.loaded) return <nav className="navbar"/>;
  const processBackClick = () => {
    successActions(true, [resetLearnData]);
    redirectToHomeCallback(true, navigate);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
        </div>
        <h5>Decision learning</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          {
            isBlank(learn.current_template) ? null :
              <li className="nav-item ms-2">
                <Link to={`/templates/${learn.current_template}/preview?learn_details=${learn.current_template}`} target={`_self`}>
                  <Button className="btn-secondary btn" >
                    Preview decision
                  </Button>
                </Link>
              </li>
          }
          <li className="nav-item ms-2 d-none">
            <Button onClick={() => setLearnFiltersSidebarOpen(!sidebar.filtersSidebar)}
                    className="btn-secondary btn">
              <i className="fas fa-filter fa-lg m-0 align-middle"/>&nbsp;
              Filters
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
}
const mapStateToProps = ({ learn, current_org, sidebar }) => ({ learn, current_org, sidebar });
export default connect(mapStateToProps, { resetLearnData, setLearnFiltersSidebarOpen })(LearnHeader);
