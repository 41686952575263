import React, {useMemo} from 'react';
import { DecisionDescription, NodePlaceholder } from "./ZTreeDecisionNode";
import RemoveDecision from './actions/RemoveDecision';
import AssignDecision from "./actions/AssignDecision";
import {connect} from "react-redux";
import DecisionSet from "../../../models/decision_set";

const ZTreeHoverDecisionNode = ({ current_user, treeNode, set, isTemplateSet = false, ...opts }) => {
  if(treeNode.loading) return <NodePlaceholder treeNode={treeNode}/>

  const isManager = isTemplateSet || new DecisionSet(set, current_user).isManager;
  const isNotAnswered = useMemo(() => !treeNode.answered, [treeNode])

  return <div className={`d-flex align-items-center h-inherit load-decision-slug-${treeNode.slug}`}>
    <DecisionDescription treeNode={treeNode}/>
    <div className="ms-auto">
      { isManager && isNotAnswered && <AssignDecision {...{treeNode}} /> }
      <RemoveDecision {...{treeNode, isTemplateSet}} {...opts} />
    </div>
  </div>
}

const mapStateToProps = ({ current_user }) => ({ current_user });
export default connect(mapStateToProps, null)(ZTreeHoverDecisionNode);
