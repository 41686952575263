import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import Decision from "../../../models/decision";
import styles from '../../styles/common'
import {compareCurrencies, currencyFormat, isBlank, isPresent} from "../../../helpers/common";
import * as moment from "moment";
import {modifyJoditText, quillIndentStyles} from "../../helpers/rich_text";
import { Html } from "react-pdf-html";
import {baseBlockMargin} from "./DecisionBlockSection";
import {SectionBlockRow} from "./DecisionDetailsPage";
import { userName } from "../../../helpers/user_helpers";

const ExpectationDataBlock = ({decisionObj}) => {
  const expectationData = decisionObj.expectationData;
  const hasWhatHappenedData = isPresent(decisionObj.lastFollowup.followup_what_happened);
  if (isBlank(expectationData)) return null;

  return <View style={[hasWhatHappenedData ? styles.mb1 : baseBlockMargin ]}>
    <Text style={[styles.h3, styles.mb0]}>{expectationData.formatLabel}</Text>
  </View>
}
const WhatHappenedBlock = ({decisionObj}) => {
  const whatHappened = decisionObj.lastFollowup.followup_what_happened
  if (isBlank(whatHappened)) return null;

  return <View style={[styles.text, ...baseBlockMargin]}>
    <Html stylesheet={quillIndentStyles()} style={styles.text}>{modifyJoditText(whatHappened)}</Html>
  </View>
}
const ResultsUserBlock = ({ decisionObj }) => {
  const lastFollowup = decisionObj.lastFollowup;
  if (isBlank(lastFollowup.user)) return null;

  return <View style={[styles.row]}>
    <Text style={[styles.text, styles.muted]}>
      Entered by {userName(lastFollowup.user)} {moment(lastFollowup.followed_up_at).format('DD MMM, yyyy')}</Text>
  </View>
}

const RealizedOpportunityBlock = ({ decisionObj }) => {
  const realizedOpportunity = decisionObj.lastFollowup.realized_value
  if (isBlank(realizedOpportunity)) return null;

  return <View style={baseBlockMargin}>
    <Text style={[styles.h2]}>{'Realized value'}</Text>
    <View>
      <Text style={[styles.text]}>
        {currencyFormat(realizedOpportunity)}&nbsp;
        {
          decisionObj.expectedOpportunityPresent ?
            <Text style={[styles.text, styles.muted]}>
              ({compareCurrencies(decisionObj.expectedOpportunity, realizedOpportunity)})
            </Text> : null
        }
      </Text>
    </View>
  </View>
}

const RealizedInvestmentBlock = ({ decisionObj }) => {
  const realizedInvestment = decisionObj.lastFollowup.realized_investment
  if (isBlank(realizedInvestment)) return null;

  return <View style={baseBlockMargin}>
    <Text style={[styles.h2]}>{'Realized investment or cost'}</Text>
    <View>
      <Text style={[styles.text]}>
        {currencyFormat(realizedInvestment)}&nbsp;
        {
          decisionObj.expectedInvestmentPresent ?
            <Text style={[styles.text, styles.muted]}>
              ({compareCurrencies(decisionObj.expectedInvestment, realizedInvestment)})
            </Text> : null
        }
      </Text>
    </View>
  </View>
}

const ChangedDecisionBlock = ({ decisionObj }) => {
  const followupChoice = decisionObj.followupChoice;
  if (isBlank(followupChoice)) return null;

  return <View style={styles.mb3}>
    <Text style={[styles.h3]}>We changed our decision:</Text>
    <View style={[styles.text]}>
      <Html stylesheet={quillIndentStyles()} style={styles.text}>{modifyJoditText(followupChoice.description)}</Html>
    </View>
  </View>
}

const DecisionResultsBlock = ({decision}) => {
  const decisionObj = new Decision(decision)
  if (!decisionObj.isDecisionWithResults || !decisionObj.isRecordedOrRecommended) return null;

  return <SectionBlockRow header={'Results'} >
    <ChangedDecisionBlock decisionObj={decisionObj}/>
    <ExpectationDataBlock decisionObj={decisionObj}/>
    <WhatHappenedBlock decisionObj={decisionObj}/>
    <RealizedOpportunityBlock decisionObj={decisionObj} />
    <RealizedInvestmentBlock decisionObj={decisionObj} />
    <ResultsUserBlock decisionObj={decisionObj}/>
  </SectionBlockRow>
}
export default DecisionResultsBlock;
