import React from 'react';
import Button from "react-bootstrap/Button";
import { isBlank } from "../../../helpers/common";
import SearchIcon from "../filters/SearchIcon";
import { isEsc } from "../../../helpers/keys_helpers";

export const resetSearchAction = (setSearchQuery, resetSearch) => {
  setSearchQuery('');
  resetSearch()
}

const SearchRow = ({
                  searchQuery,
                  placeholder = 'Search decisions',
                  setSearchQuery,
                  resetSearchAction,
                  children,
                  rowStyles = '',
                  isSearchTab = false,
                  bordered = false,
                  hideResults = false,
                  fullWidth = false
                }) => {
  const firstColClass = fullWidth ? "col-12" : "col-12 col-xl";

  const catchEsc = (e) => {
    if(isEsc(e.keyCode)) {
      e.preventDefault();
      resetSearchAction();
      return false;
    }
    return e
  }

  return (
    <div className={`row ${rowStyles} search-row`}>
      <div className={`${firstColClass} search-decisions position-relative`}>
        <SearchIcon style={{ top: '15px', right: '28px' }} />
        <Button hidden={isBlank(searchQuery)}
                onClick={resetSearchAction}
                bsPrefix="position-absolute bg-white border-0"
                style={{ top: '14px', right: '46px' }}>
          <i className="fas fa-times text-danger w-100"/>
        </Button>
        <input className={`form-control rounded ${bordered ? '' : 'border-0'} search-input`}
               autoComplete="off"
               placeholder={placeholder}
               type="text"
               onChange={(e) => setSearchQuery(e.target.value)}
               onKeyDown={catchEsc}
               onKeyPress={catchEsc}
               value={searchQuery}
        />
      </div>
      {!fullWidth && (
        <div className={`${hideResults ? 'd-none' : ''} col-auto homepage_tile search-decisions-filter-col
                         ${isSearchTab ? 'search-tab' : 'default-tab'}`}>
          {children}
        </div>
      )}
    </div>
  );
}

export default (SearchRow);
