import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import CurrencyInput from "../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep"
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedOpportunityInput = ({ setUpdatingData, decision, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const recommendation = decision.recommendation;
  const [expectedOpportunity, setExpectedOpportunity] = useState(recommendation.expected_opportunity);

  const onChangeExpectedOpporunity = (_e) => {
    setUpdatingData(true);
    updateRecommendation({
      decision_recommendation: { expected_opportunity: expectedOpportunity, slug: recommendation.slug }
    }, () => setUpdatingData(false));
  }

  const accordionObject = {
    header: <InputCurrencyHeader headerText={'Expected opportunity value'}/>,
    body: <CurrencyInput key={'recommendation-currency-input-opportunity'}
                         value={expectedOpportunity}
                         onChange={(value) => setExpectedOpportunity(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-opportunity'}
                         id={'expected-opportunity'}/>,
    defaultExpand: isPresent(recommendation.expected_opportunity)
  };

  return <div onBlur={onChangeExpectedOpporunity}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecommendation })(ExpectedOpportunityInput);
