import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
  updateRecommendationChoicesDraft,
  setRecommendationInputSidebarOpen,
  updateRecommendationFinalChoices
} from "../../../store/sidebar/actions";
import DoneButton from '../../../common/DoneButton';
import { isMobile } from "react-device-detect";
import Recommendation from "../../../models/recommendation";
import { isPresent } from "../../../helpers/common";
import { setDoneClicked } from "../../../helpers/sidebar_helpers";

const Footer = ({
                  decision, updatingData,
                  setRecommendationInputSidebarOpen, updateRecommendationFinalChoices, updateRecommendationChoicesDraft
                }) => {
  const [doneClick, setDoneClick] = useState(false);
  const recommendationObj = new Recommendation(decision?.recommendation);
  const openEndedChoice = recommendationObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !recommendationObj.isEnteredRecommendation
    && recommendationObj.isOpenEnded;

  useEffect(() => {
    if (!updatingData && doneClick) {
      setDoneClicked('recommendation-input');
      const updateAction = needChoiceSubmission() ? submitChoices : updateChoicesDraft;
      updateAction(() => {
        setRecommendationInputSidebarOpen(false);
        setDoneClick(false);
      });
    }
  }, [updatingData, doneClick]);

  const submitChoices = (callback) => {
    updateRecommendationFinalChoices(openEndedChoice.slug, {
      final_decision: true,
      reset_final: false,
      ignore_line_breaks: true,
      draft: false
    });
    callback();
  };

  const updateChoicesDraft = (callback) => {
    if (!recommendationObj.isOpenEnded) {
      updateRecommendationChoicesDraft({ draft: false }, callback);
    } else {
      callback();
    }
  };

  const onClose = () => {
    setDoneClick(true);
  };

  return <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} py-3 px-3`} style={{zIndex: 0}}>
    <DoneButton disabled={doneClick} onClose={onClose} />
  </div>
}
const mapStateToProps = ({ sidebar }) => ({ decision: sidebar.decision });
export default connect(mapStateToProps, {
  setRecommendationInputSidebarOpen,
  updateRecommendationFinalChoices,
  updateRecommendationChoicesDraft
})(Footer);
